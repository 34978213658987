const fileURL = '/files/';
export const filePath = (s: Media) => fileURL + s;
export const linkPath: (u: URL) => string = (s: URL) => urls[s];

export type URL = 'lahjoita' | 'uutiskirje';
const urls = {
    lahjoita:
        'https://www.vihreat.fi/aluevaalit-2025/?kieli=fi&vaali=aluevaalit-2025&alue=keski-uusimaa&ika_max=28&ehdokas=leinonen-santeri-6847',
    uutiskirje:
        'https://docs.google.com/forms/d/e/1FAIpQLSfiNfAAv-ZQaGNR3RgpYlNX4uWADid9EPjKLVxYKrfs8mFBvQ/viewform',
};

export type Media = Images | Symbols | SanteriLogo | GreenLogo;

export type Images =
    | 'wide_outdoors.png'
    | 'wide_lightstick.png'
    | 'wide_bridge.png'
    | 'square_sitting_rock.png'
    | 'square_leaning_train.png'
    | 'square_bricks.png'
    | 'square_childhood.png'
    | 'narrow_accordion.png'
    | 'narrow_accordion_fade.png'
    | 'narrow_peeking.png'
    | 'narrow_train.png'
    | 'wide_group.png';

export type Symbols =
    | 'drawing_black.svg'
    | 'earth.svg'
    | 'earth_g.svg'
    | 'earth_w.svg'
    | 'house.svg'
    | 'house_w.svg'
    | 'lapsi_vanhus_w.png'
    | 'lapsi_vanhus_g.png'
    | 'leaf.svg'
    | 'leaf_w.svg'
    | 'lehti_w.png'
    | 'lehti_g.png'
    | 'shield.svg'
    | 'shield_w.svg'
    | 'flag_fi.png'
    | 'flag_sv.png';

export type SanteriLogo =
    | 'slogo_black.svg'
    | 'slogo_blue.svg'
    | 'slogo_white.svg';

export type GreenLogo =
    | 'logo_grn_black.svg'
    | 'logo_grn_white.svg'
    | 'logo_grn_green.svg';

export type SocialMedia =
    | 'instagram'
    | 'facebook'
    | 'twitter'
    | 'youtube'
    | 'tiktok';

export const SocialIcons = {
    instagram: fileURL + 'logo_instagram.png',
    facebook: fileURL + 'logo_facebook.png',
    twitter: fileURL + 'logo_twitter.png',
    youtube: fileURL + 'logo_youtube.png',
    tiktok: fileURL + 'logo_tiktok.png',
};

export const SocialUrls = {
    instagram: 'https://instagram.com/santeri.p.leinonen',
    facebook: 'https://facebook.com/SanteriLeinonenVihreat',
    twitter: 'https://twitter.com/p_santeri',
    youtube: 'https://youtube.com/@santerileinonen9681',
    tiktok: 'https://tiktok.com/@ulkopolitiikkaa',
};
